// @ts-strict-ignore
import classNames from 'classnames/dedupe'
import { Fragment } from 'react'
import Col from '../../components/basic/Col'
import LinkLocale from '../../modules/i18n/LinkLocale'
import { s_footer } from '../../modules/i18n/strings/auto/wording'
import useTranslation from '../../modules/i18n/useTranslation'
import { IMG_FOOTER_FACEBOOK, IMG_FOOTER_INSTGRAM, IMG_FOOTER_NAVER_BLOG } from '../../modules/images'
import { FOOTER_LOGO_KCAB } from '../../modules/svg/svg'
import urls from '../../modules/urls'
import CenterContainer from './CenterContainer'
import VWContainer from './VWContainer'

type propsType = {
  dark?: boolean
  white?: boolean
  border?: boolean
  overlay?: boolean
  addClass?: string
  tutor?: boolean
  showMenu?: boolean
  up?: number
}

interface IFooterDoc {
  subtitle: string
  socials: IFooterDocSocials[]
  menu: IFooterDocMenu[] | null
  store: IFooterDocStore
  links?: IFooterDocLink[]
  info: string[]
  menuTutor: any | null
}

interface IFooterDocMenu {
  list: IFooterDocLink[]
}

interface IFooterDocStore {
  google: string
  apple: string
}

interface IFooterDocSocials {
  src: string
  href: string
}

interface IFooterDocLink {
  none?: boolean
  header: string
  links: IFooterDocLinkItem[]
}
interface IFooterDocLinkItem {
  none?: boolean
  name: string
  href: string
  type?: string
}

const Footer = (props: propsType) => {
  const { t, isLocaleKO, locale, isLocaleEN } = useTranslation()
  const { showMenu, dark, white, border, overlay, addClass, tutor } = props
  const bgClass = white ? 'bg-white' : dark ? 'bg-black' : 'bg-light'
  const blogUrl = `/${locale}${urls.tutor.landing.blog.index}`
  const instaUrl = 'https://www.instagram.com/ringletutor/'
  const facebookUrl = 'https://www.facebook.com/groups/ringletutor'
  const tutorDoc: IFooterDoc = {
    subtitle: '1:1 Online English Learning Service',
    socials: [
      {
        src: IMG_FOOTER_NAVER_BLOG,
        href: blogUrl,
      },
      {
        src: IMG_FOOTER_INSTGRAM,
        href: instaUrl,
      },
      {
        src: IMG_FOOTER_FACEBOOK,
        href: facebookUrl,
      },
    ],
    menu: null,
    store: {
      google: 'https://play.google.com/store/apps/details?id=com.ringletutor',
      apple: 'https://itunes.apple.com/app/ringle-tutor/id1207702138?mt=8',
    },
    menuTutor: [
      {
        list: [
          {
            header: 'SERVICE',
            links: [
              {
                name: 'About Us',
                href: urls.tutor.landing.about,
                type: null,
              },
              {
                name: 'FAQ',
                href: urls.tutor.landing.help.index,
                type: null,
              },
            ],
          },
          {
            header: 'Student',
            links: [
              {
                name: 'I am a student',
                href: urls.student.landing.home,
                type: null,
              },
            ],
          },
        ],
      },
      {
        list: [
          {
            header: 'COMMUNITY',
            links: [
              {
                name: 'Blog',
                href: blogUrl,
                type: 'blank',
              },
              {
                name: 'Facebook',
                href: facebookUrl,
                type: 'blank',
              },
              {
                name: 'Instagram',
                href: instaUrl,
                type: 'blank',
              },
            ],
          },
        ],
      },
      {
        list: [
          {
            header: 'Policy',
            links: [
              {
                name: 'Privacy Policy',
                href: urls.tutor.policy.privacy,
                type: null,
              },
              // {
              //   name: 'Terms of Service',
              //   href: urls.tutor.auth.terms,
              //   type: null,
              // },
            ],
          },
        ],
      },
    ],
    info: [
      'Ringle English Education Service (Ringle Inc.)',
      '400 Concar Dr. San Mateo, CA, United States, 94402',
      '',
      'tutor@ringleplus.com',
    ],
  }

  // 개인정보
  const policyMenu: IFooterDocLink = {
    header: t(s_footer.policy),
    links: [
      {
        name: t(s_footer.terms_of_privacy),
        href: urls.tutor.policy.privacy,
        type: null,
      },
      {
        name: t(s_footer.terms_of_service),
        href: urls.tutor.policy.terms,
        type: null,
      },
    ],
  }
  const companyInfo: any[] = [
    { title: tutor ? 'Ringle English Education Service (Ringle Inc.)' : t(s_footer.ringle_company) },
    [
      tutor ? '400 Concar Dr. San Mateo, CA, United States, 94402' : t(s_footer.ringle_address),
      tutor ? '' : t(s_footer.ringle_phone),
      tutor ? 'tutor@ringleplus.com' : t(s_footer.ringle_ceo),
    ],
  ]
  const doc = tutorDoc

  return (
    <>
      <VWContainer bgColor="bg-black">
        <CenterContainer>
          <section className={classNames({ 'bg-black': dark }, { 'pt-16': overlay }, addClass)}>
            <footer className={classNames('py-48 md:py-96', bgClass)}>
              <div>
                {showMenu && doc.menu && (
                  <div className="row mb-[60px]">
                    {doc.menu?.map((item, i) => (
                      <Fragment key={i}>
                        <Col clx="col-12 md:col-6 lg:col">
                          {item.list
                            .filter((e) => !e.none)
                            .map((e, j) => (
                              <Fragment key={j}>
                                {e.header ? (
                                  <h4 className={classNames({ 'text-white': dark }, 'text-h4 mb-[10px] mt-[48px]')}>
                                    {e.header}
                                  </h4>
                                ) : (
                                  <h4 className={classNames({ 'text-white': dark }, 'text-h4 smd:hidden mb-[16px]')}>
                                    &nbsp;
                                  </h4>
                                )}
                                <ul>
                                  {e.links
                                    .filter((e) => !e.none)
                                    .map((link, i) => {
                                      return (
                                        <li key={i} className="mb-[8px] text-gray-300">
                                          {link.type === 'blank' ? (
                                            <a href={link.href} target="_blank" rel="noreferrer">
                                              {link.name}
                                            </a>
                                          ) : (
                                            <LinkLocale href={link.href}>{link.name}</LinkLocale>
                                          )}
                                        </li>
                                      )
                                    })}
                                </ul>
                                {i === doc.menu?.length - 1 && isLocaleKO && (
                                  <Col clx="col-12 md:col-6 lg:col">
                                    <ul className="flex flex-row items-center">
                                      {doc.socials?.map((social, i) => (
                                        <li key={i} className="mr-[8px] w-[40px]">
                                          <a
                                            target="_blank"
                                            href={social.href}
                                            className="text-decoration-none"
                                            rel="noreferrer"
                                          >
                                            <img
                                              src={social.src}
                                              className="list-social-icon"
                                              alt={social.href}
                                              width="45px"
                                            />
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </Col>
                                )}
                              </Fragment>
                            ))}
                        </Col>
                      </Fragment>
                    ))}
                  </div>
                )}
                <div className="row">
                  <Col clx="col-12 md:col-6 order-1 md:order-0">
                    {tutor || <img src={FOOTER_LOGO_KCAB} className="mb-[68px] max-w-none" />}
                    <img src="/assets/img/logo/text_logo_white.svg" className="mb-12 max-w-none" />

                    {/* 회사 정보 */}
                    <>
                      <Col mb24>
                        <div>
                          <p className="text-sBody text-white">{companyInfo[0].title}</p>
                          {companyInfo[1].map((row, i) => (
                            <p key={i} className="text-sBody text-gray-300">
                              <Fragment key={i}>{row}</Fragment>
                            </p>
                          ))}
                        </div>
                      </Col>
                    </>
                    <div className="mb-[24px] flex flex-row items-center">
                      {tutor ||
                        policyMenu.links.map((item, i) => (
                          <Fragment key={i}>
                            <LinkLocale href={item.href}>
                              <div className="text-sBody text-white">{item.name}</div>
                            </LinkLocale>
                            <div
                              className={classNames([i == 1 && 'hidden'], 'mx-[32px] h-[18px] w-[1px] bg-white')}
                            ></div>
                          </Fragment>
                        ))}
                    </div>

                    <div className="mt-[24px]">
                      <a
                        target="_blank"
                        href={doc.store.apple}
                        className="text-reset lift w-128 mr-12 inline-block"
                        rel="noreferrer"
                      >
                        <img src="/assets/img/buttons/button-app.png" />
                      </a>
                      <a
                        target="_blank"
                        href={doc.store.google}
                        className="text-reset lift w-128 inline-block"
                        rel="noreferrer"
                      >
                        <img src="/assets/img/buttons/button-play.png" />
                      </a>
                    </div>
                  </Col>
                  <Col clx="col-12 md:col-6 order-0 md:order-1">
                    <div className="row">
                      {tutor &&
                        doc.menuTutor
                          ?.filter((et) => !et.none)
                          .map((et, i) => (
                            <div className="col-4 flex flex-col px-[20px]" key={i}>
                              {et.list.map((e, j) => (
                                <Fragment key={j}>
                                  {e.header && (
                                    <h5
                                      className={classNames('font-weight-bold text-uppercase mb-4', {
                                        'text-gray-200': dark,
                                      })}
                                    >
                                      {e.header}
                                    </h5>
                                  )}
                                  {e.links && (
                                    <ul className="list-unstyled text-muted mb-24">
                                      {e.links
                                        ?.filter((e) => !e.none)
                                        .map((link, i) => (
                                          <li key={i} className="mb-12">
                                            {link.type == 'blank' ? (
                                              <a
                                                href={link.href}
                                                rel="noreferrer"
                                                target="_blank"
                                                className="text-gray-300"
                                              >
                                                {link.name}
                                              </a>
                                            ) : (
                                              <LinkLocale href={link.href} className="text-gray-300">
                                                {link.name}
                                              </LinkLocale>
                                            )}
                                          </li>
                                        ))}
                                    </ul>
                                  )}
                                </Fragment>
                              ))}
                            </div>
                          ))}
                    </div>
                  </Col>
                </div>
              </div>
            </footer>
          </section>
        </CenterContainer>
      </VWContainer>
    </>
  )
}

export default Footer
