import { useEffect, useState } from 'react'

const useViewportWidth = () => {
  const [netWidth, setNetWidth] = useState(0)
  const [totalWidth, setTotalWidth] = useState(0)
  useEffect(() => {
    if (window.screen.width < window.innerWidth) {
      setTotalWidth(window.screen.width)
    } else {
      setTotalWidth(window.innerWidth)
    }

    setNetWidth(document.documentElement.clientWidth)
  }, [])
  return { netWidth, totalWidth }
}

export default useViewportWidth
