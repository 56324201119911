// @ts-strict-ignore
import classNames from 'classnames'
import React from 'react'
import useViewportWidth from '../../modules/hooks/common/useViewportWidth'

type VWContainerPropsType = {
  children?: any
  bgColor?: any
  clx?: any
  onClick?: () => void
  id?: any
}

const VWContainer: React.FC<VWContainerPropsType> = ({ children, bgColor, clx, onClick, id }) => {
  const { totalWidth, netWidth } = useViewportWidth()
  return (
    <div
      style={{
        marginLeft: `calc(-50vw + ${totalWidth - netWidth}px / 2)`,
        marginRight: `calc(-50vw + ${totalWidth - netWidth}px / 2)`,
        width: `calc(100vw - ${totalWidth - netWidth}px)`,
      }}
      onClick={onClick}
      id={id}
      className={classNames(classNames('relative left-1/2 right-1/2', bgColor, clx))}
    >
      <div className="mx-auto">{children}</div>
    </div>
  )
}

export default VWContainer
